import React, { useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { PayslipModel } from "../models";
import { EmailModal } from "./EmailModal";

import download from "downloadjs";
import { useHttpClient } from "@laingorourke/core-web-httpclient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { HttpClientKey } from "../helpers";

export const PayslipTableRow: React.FC<PayslipTableRowProps> = (props) => {

    const p = props.payslip;

    const [showEmailModal, setShowEmailModal] = useState(false);

    const httpClient = useHttpClient(HttpClientKey).client;

    const downloadPayslip = () => {
        httpClient.get<Blob>(`payslips/${p.id}/download`, {
            responseType: "blob",
        }).then((r) =>
            download(r.data, `${p.payslipDateDisplay}.pdf`, r.headers["content-type"])
        );
    }

    return (
        <>
            <div className="d-none d-lg-block text-center">
                <ButtonGroup>
                    <Button onClick={downloadPayslip} variant="success" size="sm">View</Button>
                    <div className="or or-sm" />
                    <Button onClick={() => { setShowEmailModal(true) }} variant="danger" size="sm">Email</Button>
                </ButtonGroup>
            </div>
            <div className="d-lg-none d-md-block">
                <Dropdown>
                    <Dropdown.Toggle variant="default">
                        <FontAwesomeIcon icon={faBars} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={downloadPayslip}>View Payslip</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setShowEmailModal(true) }}>Email Payslip</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <EmailModal payslipId={p.id} show={showEmailModal} onClose={() => setShowEmailModal(false)} />
        </>
    );

}

export interface PayslipTableRowProps {
    payslip: PayslipModel;
}
