import "./App.scss";

import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { useApps } from "@laingorourke/core-services-apps-web-client";
import { createMenu, InProgress, Layout, Router, ToastPosition, ToastProvider } from "@laingorourke/core-web-components";
import { MenuItem } from "@laingorourke/core-web-types";

import { AppDisplayName, getAppsServiceConfiguration, getCurrentVersion, getMyTasksConfiguration, LogoUrl } from "./helpers";
import routes, { emptyRoutes, registrationRoutes } from "./routes";
import { useAuth0 } from "@auth0/auth0-react";
import { useIsRegistered } from "./services";
import { useUser } from "@laingorourke/core-web-auth0";

export interface AppProps {
    numberOfTodos?: number;
}

const App: React.FC<AppProps> = ({ numberOfTodos }) => {

    const apps = useApps(getAppsServiceConfiguration()?.baseUrl);

    const {
        logout,
        isAuthenticated,
        isLoading,
    } = useAuth0();

    const user = useUser();

    const { data, refetch } = useIsRegistered();

    useEffect(() => {
        refetch()
    }, [isLoading, isAuthenticated]);

    const mainMenu: MenuItem[] = [];

    const menuItems = createMenu("", mainMenu, routes);

    const tasksBadge = numberOfTodos !== undefined && numberOfTodos !== null ?
    {
        count: numberOfTodos,
        link: getMyTasksConfiguration().baseUrl
    } : undefined;

    return (
        <BrowserRouter>
            <InProgress loading={false} />
            {!isAuthenticated && <img src={LogoUrl} className="launch-logo" alt={AppDisplayName} />}
            {isAuthenticated &&
                <ToastProvider position={ToastPosition.TopRight} >
                    <Layout>
                        <Layout.Main>
                            <Layout.Header
                                sticky
                                brand={{
                                    logo: LogoUrl,
                                    name: AppDisplayName
                                }}
                                tasksBadge={tasksBadge}
                                appLauncher={{
                                    apps: apps,
                                    supportLinks: {
                                        helpUrl: routes.help.path
                                    }
                                }}
                                navigation={menuItems}
                                userMenu={{
                                    user: user,
                                    onLogout: () => logout({ returnTo: window.location.origin }),
                                }} />
                            <Router
                                routes={data === undefined ? emptyRoutes : data === true ? routes : registrationRoutes}
                                appName={AppDisplayName}
                                redirects={[]} />
                        </Layout.Main>
                        <Layout.Footer copyrightYear={2019} version={getCurrentVersion()} appDisplayName={AppDisplayName} />
                    </Layout>
                </ToastProvider>
            }
        </BrowserRouter>
    );
}

export default App;
