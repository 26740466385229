import { ToastMessageType, useToasts } from "@laingorourke/core-web-components";
import { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { useRegister } from "../services";

export const Registration = () => {

    const [payrollId, setPayrollId] = useState("");
    const [registrationToken, setRegistrationToken] = useState("");

    const register = useRegister();
    const toasts = useToasts();

    const handleSubmit = () => {
        console.debug("Here!");
        register.mutate([null, { payrollId, registrationToken }], {
            onSuccess: () => {
                window.location.href = "/";
            },
            onError: (e) => {

                toasts.newMessage({
                    title: "Error",
                    type: ToastMessageType.Error,
                    body: e.response?.data?.message ?? e.message,
                    closeable: true,
                });
            }
        });
    }

    return (
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title>Payroll Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="registration-form" className="form-horizontal">
                    <Form.Group controlId="payroll-id">
                        <Form.Label>Payroll ID</Form.Label>
                        <Form.Control type="number"
                            inputMode="numeric"
                            value={payrollId}
                            className="form-control"
                            onChange={(e) => setPayrollId(e.currentTarget.value)} />
                    </Form.Group>
                    <Form.Group controlId="registration-token">
                        <Form.Label>Registration Token</Form.Label>
                        <Form.Control type="text"
                            value={registrationToken}
                            className="form-control"
                            onChange={(e) => setRegistrationToken(e.currentTarget.value)} />
                    </Form.Group>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    data-style="slide-right"
                    disabled={payrollId.trim() === "" || registrationToken.trim() === ""}
                    style={{ width: "100px" }}
                    className="btn btn-primary ladda-button"
                    onClick={handleSubmit} >
                    <span className="ladda-label">Register</span>
                </button>
            </Modal.Footer>
        </Modal>
    );
}
