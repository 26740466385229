import { Routes } from "@laingorourke/core-web-types";
import { Home, Help, Registration } from "./pages";

const routes: Routes = {
    home: {
        path: "/",
        title: "Home",
        exact: true,
        component: <Home />
    },
    help: {
        path: "/help",
        title: "Help",
        exact: true,
        component: <Help />
    },
    registration: {
        path: "/registration",
        title: "Registration",
        exact: true,
        component: <Registration />
    },
};

export const registrationRoutes: Routes = {
    register: {
        path: "/",
        title: "Register",
        component: <Registration />
    }
}

export const emptyRoutes: Routes = {}

export default routes;
