import React, { useMemo, useState } from "react";
import { PayslipModel } from "../models";
import { PayslipTableRow } from "./PayslipTableRow";
import { ColumnDefinition, Table } from "@laingorourke/core-web-components";

export const PayslipTable: React.FC<PayslipTableProps> = (props) => {

    const columns: ColumnDefinition<PayslipModel>[] = [
        {
            field: (r) => r.id,
            renderer: (_cell, r) => <PayslipTableRow payslip={r} />,
            headerClass: "column-15"
        },
        {
            field: (r) => r.payslipDateDisplay,
            sortable: true,
            text: "Date",
        },
        {
            field: (r) => r.payslipType === null ? "Standard" : r.payslipType,
            text: "Pay Type",
            sortable: true
        }

    ];

    return (
        <Table<PayslipModel>
            keySelector={r => r.id}
            data={props.payslips ?? []}
            columns={columns}
            currentPage={1}
            pageSize={10}
            pageSizes={[10, 20, 50]}
            sortColumnIndex={1}
            sortDirection="desc"
            cacheKey="payslips"
        />
    );
}

export interface PayslipTableProps {
    payslips?: PayslipModel[] | null;
}
