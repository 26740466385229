import React from "react";
import { Alert } from "react-bootstrap";

import { AppDisplayName, getCurrentVersion, LogoUrl } from "../helpers";
import { Page, Layout } from "@laingorourke/core-web-components";
import { BrowserRouter } from "react-router-dom";

export const ErrorPage: React.FC<ErrorPageProps> = (props) => {

    return (
        <BrowserRouter>
            <Layout>
                <Layout.Main>
                    <Layout.Header
                        sticky
                        brand={{
                            logo: LogoUrl,
                            name: AppDisplayName
                        }}
                    />
                    <Page header={{
                        title: "An error has occurred inside Payslips."
                    }}>
                        <Alert>
                            <h2>Please contact the <a href="mailto:itservicedesk@laingorourke.com.au">Service Desk</a>, and send them this message:</h2>
                            {props.message && <span dangerouslySetInnerHTML={{ __html: props.message }} />}
                        </Alert>
                    </Page>
                </Layout.Main>
                <Layout.Footer copyrightYear={2019} version={getCurrentVersion()} appDisplayName={AppDisplayName} />
            </Layout>
        </BrowserRouter>
    );
}

export interface ErrorPageProps {
    message: string;
}
