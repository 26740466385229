import { Page } from "@laingorourke/core-web-components";

export const Help = () => {
    return (
        <Page header={{ title: "Help" }}>
            <Page.Section>
                <h2>Website Issues/Questions</h2>
                <p>If you are experiencing any issues using this website, please contact the IT Service Desk for assistance: <a href="mailto:ITServiceDesk@laingorourke.com.au">ITServiceDesk@laingorourke.com.au</a></p>
                <br /><br />
                <h2>Payslip Issues/Questions</h2>
                <h4>Experiencing issues or have questions about your payslips?</h4>
                <p>If you are a wages employee on weekly pay frequency please contact <a href="mailto:weeklytimesheetsaus@laingorourke.com.au">WeeklyTimesheetsAUS@laingorourke.com.au</a></p>
                <p>If you are monthly paid salaried staff please contact <a href="mailto:PayrollMonthlyAustralia@laingorourke.com.au">PayrollMonthlyAustralia@laingorourke.com.au</a></p>
            </Page.Section>
        </Page>

    );
}
