import { useMutation, UseMutationOptions } from "react-query";
import { useHttpClient } from "@laingorourke/core-web-httpclient";
import { AxiosError } from "axios";
import { HttpClientKey } from "../helpers";

export const useApiPost = <Response, Variables, Data = null>(path: (variables: Variables) => string, options?: UseMutationOptions<Response, AxiosError<Response>, [Variables, Data]>) => {

    const httpClient = useHttpClient(HttpClientKey).client;

    return useMutation<Response, AxiosError<Response>, [Variables, Data]>(async ([variables, data]): Promise<Response> => await httpClient.post(path(variables), data), options);
}


export const useApiDatalessPost = <Response, Variables>(path: (variables: Variables) => string, options?: UseMutationOptions<Response, AxiosError<Response>, Variables>) => {

    const httpClient = useHttpClient(HttpClientKey).client;

    return useMutation<Response, AxiosError<Response>, Variables>(async (variables): Promise<Response> => await httpClient.post(path(variables)), options);
}
