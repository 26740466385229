import { ToastMessageType, useToasts } from "@laingorourke/core-web-components";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { useEmailPayslip } from "../services";

export const EmailModal: React.FC<EmailModalProps> = (props) => {

    const [password, setPassword] = useState("");
    const [passwordVerification, setPasswordVerification] = useState("");
    const [passwordProtect, setPasswordProtect] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");

    const toasts = useToasts();
    const sendEmail = useEmailPayslip();

    const validate = () => true;

    const handleSubmit = () => {
        if (!validate()) return;
        if (passwordProtect) {
            if (password !== passwordVerification) {
                toasts.newMessage({
                    title: "Passwords do not match, please ensure the same password is entered into both fields",
                    type: ToastMessageType.Error,
                    closeable: true,
                });
                return;
            }

            if (password === "") {
                toasts.newMessage({
                    title: "Please enter a password",
                    type: ToastMessageType.Error,
                    closeable: true,
                });
                return;
            }
        }

        var data = {
            payslipId: props.payslipId,
            password: password,
            passwordProtect: passwordProtect,
            emailAddress: emailAddress
        };

        sendEmail.mutate([null, data], {
            onSuccess: () => {
                toasts.newMessage({
                    title: "Success",
                    body: `Payslip sent to ${emailAddress}`,
                    type: ToastMessageType.Success,
                    closeable: true,
                });
                props.onClose();
            },
            onError: () => {
                toasts.newMessage({
                    title: "Error",
                    delay: 5000,
                    body: "An error occurred while emailing payslips",
                    type: ToastMessageType.Error,
                    closeable: true,
                });
            }
        });
    }

    return (
        <Modal show={props.show} onHide={props.onClose} centered autoFocus>
            <Modal.Header closeButton>
                <Modal.Title>Email Payslip</Modal.Title>
            </Modal.Header>
            {
                //While the employee details functionality is being hidden, we always want to
                //show the emails fields. Once live the logic will be as follows:
                //
                //  this.props.employee.email === null || !this.props.employee.emailVerified
            }
            {false
                ? (<div>
                    <div className="modal-body">
                        <div className="row">
                            <form id="email-form">
                                <div className="col-md-12">
                                    You do not currently have an email address registered on your account or your email address has not been verified.
                                    <br /><br />
                                    Please ensure that you have an email address registered via the <a href="/employeedetails">Employee Details</a> screen and have verified your email using link in the verification email.
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="pull-right">
                            <button type="button" className="btn btn-default btn-sm" onClick={props.onClose}>Close</button>
                        </div>
                    </div>
                </div>)
                :
                <>
                    <Modal.Body>
                        <form id="email-form">
                            <Form.Group controlId="email-address">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email"
                                    value={emailAddress}
                                    data-parsley-required={true}
                                    data-parsley-trigger="blur change keyup"
                                    onChange={(e) => setEmailAddress(e.currentTarget.value)} />
                            </Form.Group>
                            <Form.Group controlId="password-protect">
                                <Form.Label>Would you like to password protect your payslip?</Form.Label>
                                <Form.Select
                                    value={passwordProtect ? "true" : "false"}
                                    onChange={(e) => setPasswordProtect(e.currentTarget.value === "true")}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </Form.Select>
                            </Form.Group>
                            {passwordProtect
                                ? <>
                                    <Form.Group controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            value={password}
                                            required
                                            className="form-control"
                                            onChange={(e) => setPassword(e.currentTarget.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="confirm-password">
                                        <Form.Label>Re-type Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            value={passwordVerification}
                                            name="passwordVerification"
                                            required
                                            className="form-control"
                                            onChange={(e) => setPasswordVerification(e.currentTarget.value)} />
                                    </Form.Group>
                                </>
                                : null
                            }
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="pull-right">
                            <Button variant="link" onClick={props.onClose}>Cancel</Button>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm ladda-button"
                                onClick={handleSubmit}>
                                <span className="ladda-label">Send</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </>

            }
        </Modal>);
}

export interface EmailModalProps {
    payslipId: string;
    show: boolean;
    onClose: () => void;
}
