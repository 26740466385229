import { Auth0Provider } from "@auth0/auth0-react";
import { configureApiCheck, HttpClientProvider } from "@laingorourke/core-web-httpclient";
import { MyTasksProvider } from "@laingorourke/core-web-mytasksreactsdk";
import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query"

import PayslipsApp from "./PayslipsApp";
import { populateConfiguration, getAuthConfiguration, getMyTasksConfiguration, HttpClientKey, LogoUrl } from "./helpers";
import { ErrorPage } from "./pages";
import reportWebVitals from "./reportWebVitals";

const root = createRoot(document.getElementById("root")!);

root.render(
    <img
        className="launch-logo"
        alt="Payslips Logo"
        src={LogoUrl}
    />
);

populateConfiguration().then(() => {
    const auth0Config = getAuthConfiguration();
    const myTasksConfig = getMyTasksConfiguration();

    const queryClient = new QueryClient();

    root.render(
        <React.StrictMode>
            <Auth0Provider audience={auth0Config.audience} clientId={auth0Config.clientId} domain={auth0Config.domain} redirectUri={window.location.origin}>
                <HttpClientProvider resources={[{ baseUrl: "/api", configure: configureApiCheck, audience: auth0Config.audience, key: HttpClientKey }]}>
                    <MyTasksProvider baseUrl={`${myTasksConfig?.baseUrl!}/api`} audience={myTasksConfig?.audience}>
                        <QueryClientProvider client={queryClient}>
                            <PayslipsApp />
                        </QueryClientProvider>
                    </MyTasksProvider>
                </HttpClientProvider>
            </Auth0Provider>
        </React.StrictMode>
    );
}).catch((error: Error) => {
    root.render(<ErrorPage message={error.message} />);
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
