import { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import App from "./App";
import { useUser } from "@laingorourke/core-web-auth0";
import { TaskWrapper } from "./TaskWrapper";
import { AppDisplayName, LogoUrl } from "./helpers";

// We must not even try to get MyTasks counts for users who use social log in.
// These types of users seem to cause an endless login loop
const PayslipsApp = () => {

    const {
        loginWithRedirect,
        isAuthenticated,
        isLoading,
    } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated]);

    const user = useUser();

    if (!isAuthenticated || !user)
    {
        return <img src={LogoUrl} className="launch-logo" alt={AppDisplayName} />
    }

    if (user?.isExternal === undefined || user.isExternal === true)
    {
        return <App />;
    }

    if (user.isExternal === false) return <TaskWrapper />;

    return <></>;
}

export default PayslipsApp;
