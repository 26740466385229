import { InProgress, Page } from "@laingorourke/core-web-components";
import { useHttpClient } from "@laingorourke/core-web-httpclient";
import React from "react";
import { Button } from "react-bootstrap";
import download from "downloadjs";

import { PayslipTable } from "../components";
import { usePayslips } from "../services/payslips";
import { HttpClientKey } from "../helpers";

export const Home: React.FC = () => {

    const { isLoading, data } = usePayslips();

    const httpClient = useHttpClient(HttpClientKey).client;

    const downloadAll = () => {
        httpClient.get<Blob>("payslips/download", {
            responseType: "blob",
        }).then((r) =>
            download(r.data, "Payslips.zip", r.headers["content-type"])
        );
    }

    return (
        <Page title="My Payslips" header={
            <Page.Header title="My Payslips" actions={[<Button onClick={downloadAll} variant="primary" disabled={!data?.data || data.data.length === 0}>Download All</Button>]} />}>
            <InProgress loading={isLoading} />
            <PayslipTable payslips={data?.data} />
        </Page>
    );
}
