import { useTasksCount } from "@laingorourke/core-web-mytasksreactsdk";
import App from "./App";

export const TaskWrapper = () => {

    const myTasksTotal = useTasksCount();

    return (
        <App numberOfTodos={myTasksTotal.data?.numberOfTodos} />
    );
}
