import { RegistrationModel, ResultModel } from "../models";
import { useApiPost } from "./useApiPost";
import { useHttpClient } from "@laingorourke/core-web-httpclient";
import { useQuery } from "react-query";
import { HttpClientKey } from "../helpers";

export const useIsRegistered = () => {
    const client = useHttpClient(HttpClientKey).client;

    /*    const get = async (): Promise<boolean> => {
            const respone = await client.get(`/employee/registered`, {
                validateStatus: (status) => status === 200 || status === 401,
            });

            return respone.status === 200;
        }

        return get;*/

    return useQuery(["isRegistered"], async () => {
        const response = await client.get(`/employee/registered`, {
            validateStatus: (status) => status === 200 || status === 401,
        });
        return response.status === 200;
    }, {
        //cacheTime: 3600000,
        refetchOnWindowFocus: false,
    });
}

export const useRegister = () => useApiPost<ResultModel, null, RegistrationModel>(() => `/registration`);
